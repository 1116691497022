<template>
  <div>
    <LandingHeroSection />

    <LandingServicesSection id="services" />

    <LandingVideoSection />

    <LandingFaqSection id="faq" />

    <LandingCtaSection />
  </div>
</template>
