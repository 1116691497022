<template>
  <section class="py-10 bg-black text-white sm:py-16">
    <div
      class="grid grid-cols-1 sm:grid-cols-2 gap-8 px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl"
    >
      <div class="flex justify-center sm:justify-end items-center">
        <iframe
          v-if="shouldLoadVideo"
          src="https://www.youtube.com/embed/SqNdXD4nvug?autoplay=1&mute=1&loop=1&rel=0"
          loading="lazy"
          title="GymBlaze Video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          class="aspect-[9/16] max-h-[70vh]"
        ></iframe>
      </div>
      <div class="flex justify-center sm:justify-start items-center">
        <ul
          class="flex flex-col gap-8 text-lg font-medium text-center sm:text-left"
        >
          <li>
            <Icon
              name="hugeicons:equipment-gym-03"
              class="w-6 h-6 sm:w-12 sm:h-12 mr-2"
            />
            Private gym pod
          </li>
          <li>
            <Icon
              name="mdi:certificate"
              size="60"
              class="w-6 h-6 sm:w-12 sm:h-12 mr-2"
            />
            Personal trainer bersertifikasi
          </li>
          <li>
            <Icon
              name="flowbite:profile-card-solid"
              size="60"
              class="w-6 h-6 sm:w-12 sm:h-12 mr-2"
            />
            Tanpa komitmen keanggotaan
          </li>
          <li>
            <Icon
              name="fa-solid:user-friends"
              size="60"
              class="w-6 h-6 sm:w-12 sm:h-12 mr-2"
            />
            Berlatih bersama teman
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const shouldLoadVideo = ref(false);

onMounted(async () => {
  await nextTick();
  shouldLoadVideo.value = true;
});
</script>
