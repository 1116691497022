import payload_plugin_gjhV1T0HmN from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@12.7.0_encoding@0.1.13__firebase@_davlulgxsa24n33arfdln24wtm/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_6ASv7MlXa1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.9.3_encoding@0.1.13_eslint@9.18.0_jiti@2._ahtcxgzwi2ebeat53m2bp5hofi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tzXcxvUrkS from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.9.3_encoding@0.1.13_eslint@9.18.0_jiti@2._ahtcxgzwi2ebeat53m2bp5hofi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bhdQuvAOmz from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.9.3_encoding@0.1.13_eslint@9.18.0_jiti@2._ahtcxgzwi2ebeat53m2bp5hofi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fHnAkQYboP from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.3_sass-embe_v4xyppy2egvnkxpn3afrscsazu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_qA6do63g0y from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.9.3_encoding@0.1.13_eslint@9.18.0_jiti@2._ahtcxgzwi2ebeat53m2bp5hofi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_a756CQnls6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.9.3_encoding@0.1.13_eslint@9.18.0_jiti@2._ahtcxgzwi2ebeat53m2bp5hofi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_5lGFsj3zeQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.9.3_encoding@0.1.13_eslint@9.18.0_jiti@2._ahtcxgzwi2ebeat53m2bp5hofi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_iQvIzYhuXm from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.9.3_encoding@0.1.13_eslint@9.18.0_jiti@2._ahtcxgzwi2ebeat53m2bp5hofi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_bBhSAsyzWF from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.9.3_encoding@0.1.13_eslint@9.18.0_jiti@2._ahtcxgzwi2ebeat53m2bp5hofi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_pTjNsauU7s from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.50.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.30.1_@opentelemetry+api@1._l5h45yhhlhyr32qdgeyvhi44ce/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import components_client_nza5rGF9Ds from "/vercel/path0/node_modules/.pnpm/nuxt-swiper@2.0.0_magicast@0.3.5_rollup@4.27.3/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/vercel/path0/.nuxt/pwa-icons-plugin.ts";
import pwa_client_D5ZY866KdX from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.3_sass-embedd_fl3qsh73o4nlodce7opmpia3zi/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_client_Zp67FBimqy from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@12.7.0_encoding@0.1.13__firebase@_davlulgxsa24n33arfdln24wtm/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/vercel/path0/.nuxt/vuefire-auth-plugin.client.mjs";
import plugin_mint_cookie_client_D3DfJz4li6 from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@12.7.0_encoding@0.1.13__firebase@_davlulgxsa24n33arfdln24wtm/node_modules/nuxt-vuefire/dist/runtime/auth/plugin-mint-cookie.client.js";
import vuefire_plugin_8cq4FnzXKb from "/vercel/path0/.nuxt/vuefire-plugin.mjs";
import plugin_client_5KrfbrMZIK from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@12.7.0_encoding@0.1.13__firebase@_davlulgxsa24n33arfdln24wtm/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import plugin_client_0iny64hdbU from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@1.2.1_magicast@0.3.5_rollup@4.27.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import titles_QqSTdVun0l from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.1_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.3_j3lrlc6orflofdp7xnu4gnsgse/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_Vw0fw5qQwN from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.1_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.3_j3lrlc6orflofdp7xnu4gnsgse/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_nwxP6jb5At from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.3_sass-e_fexndbd66nvdhqyvrxyfeaggke/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_xpNybXwLhn from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@22.9.3_sass-e_fexndbd66nvdhqyvrxyfeaggke/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_L2wiJEvTZV from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.27.3_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_3uw3KVYrBR from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.27.3_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_wy0B721ODc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_6Ph1iQzNHk from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
import dompurify_html_VcfsMfUvBB from "/vercel/path0/plugins/dompurify-html.ts";
import v_calendar_dxaVzst4iS from "/vercel/path0/plugins/v-calendar.ts";
export default [
  payload_plugin_gjhV1T0HmN,
  revive_payload_client_6ASv7MlXa1,
  unhead_tzXcxvUrkS,
  router_bhdQuvAOmz,
  _0_siteConfig_fHnAkQYboP,
  payload_client_qA6do63g0y,
  navigation_repaint_client_a756CQnls6,
  check_outdated_build_client_5lGFsj3zeQ,
  chunk_reload_client_iQvIzYhuXm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bBhSAsyzWF,
  sentry_client_pTjNsauU7s,
  sentry_client_config_o34nk2sJbg,
  components_client_nza5rGF9Ds,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_D5ZY866KdX,
  plugin_client_Zp67FBimqy,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  plugin_mint_cookie_client_D3DfJz4li6,
  vuefire_plugin_8cq4FnzXKb,
  plugin_client_5KrfbrMZIK,
  plugin_client_0iny64hdbU,
  titles_QqSTdVun0l,
  defaultsWaitI18n_Vw0fw5qQwN,
  siteConfig_nwxP6jb5At,
  inferSeoMetaPlugin_xpNybXwLhn,
  switch_locale_path_ssr_L2wiJEvTZV,
  i18n_3uw3KVYrBR,
  plugin_wy0B721ODc,
  plugin_6Ph1iQzNHk,
  analytics_client_mtqVw3YRXk,
  dompurify_html_VcfsMfUvBB,
  v_calendar_dxaVzst4iS
]