<template>
  <div
    class="bg-white text-black border border-gray-200"
    :class="{ 'shadow-lg': isActive }"
  >
    <button
      class="flex items-center justify-between w-full px-4 py-5 sm:p-6 cursor-pointer"
      @click="isActive = !isActive"
    >
      <span class="flex text-lg text-start font-semibold text-black">
        {{ title }}
      </span>
      <Icon
        name="fa6-solid:angle-up"
        class="w-6 h-6 text-gray-400"
        :class="{ 'rotate-180': isActive }"
      />
    </button>

    <div v-show="isActive" class="px-4 pb-5 sm:px-6 sm:pb-6">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  title: string;
}>();

const isActive = ref(false);
</script>
