<template>
  <ClientOnly>
    <div v-if="$pwa?.needRefresh" class="pwa-toast" role="alert">
      <div class="message">
        <span> New update available, click on reload button to update. </span>
      </div>
      <button @click="$pwa.updateServiceWorker()">Reload</button>
      <button @click="$pwa.cancelPrompt()">Close</button>
    </div>
    <div
      v-if="
        $pwa?.showInstallPrompt && !$pwa?.offlineReady && !$pwa?.needRefresh
      "
      class="pwa-toast"
      role="alert"
    >
      <div class="message">
        <span>Install GymBlaze app?</span>
      </div>
      <button @click="$pwa.install()">Install</button>
      <button @click="$pwa.cancelInstall()">Cancel</button>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
onMounted(() => {
  const { $pwa } = useNuxtApp();
  if (import.meta.client && $pwa.needRefresh) {
    $pwa.updateServiceWorker();
  }
});
</script>

<style scoped lang="scss">
.pwa-toast {
  @apply fixed right-0 bottom-0 bg-white text-black border border-black rounded m-4 p-4 z-50;
}

.pwa-toast .message {
  @apply mb-2;
}

.pwa-toast button {
  @apply border border-black mr-2 rounded py-1 px-2;
}
</style>
