<template>
  <section class="bg-black py-20 lg:py-24">
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
      <div class="max-w-2xl mx-auto text-center">
        <h2
          class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl"
        >
          Frequently Asked Question
        </h2>
        <p
          class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-200"
        >
          Temukan jawaban dari pertanyaanmu
        </p>
      </div>

      <div class="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
        <AppAccordion
          v-for="faq in faqContent"
          :key="faq.name"
          :title="faq.name"
        >
          <p>
            {{ faq.acceptedAnswer }}
          </p>
        </AppAccordion>
      </div>

      <p class="text-center text-gray-200 textbase mt-9 text-balance">
        Tidak menemukan jawaban yang kamu cari? Hubungi
        <AppLink href="mailto:info@gymblaze.id">info@gymblaze.id</AppLink>
      </p>
    </div>
  </section>
</template>

<script setup lang="ts">
const faqContent = [
  {
    name: "Apa itu GymBlaze?",
    acceptedAnswer: `GymBlaze adalah jasa penyewaan gym pribadi yang menawarkan solusi
            kebugaran yang fleksibel dan tanpa komitmen. Anda dapat menikmati sesi
            latihan yang dipersonalisasi di ruang pribadi, berlatih bersama teman-teman,
            dan mendapatkan manfaat dari program pelatih pribadi ahli.`,
  },
  {
    name: "Bagaimana cara memesan sesi?",
    acceptedAnswer: `Memesan sesi dengan GymBlaze sangat mudah. Cukup kunjungi situs
            web kami, klik tombol "Pesan Sekarang", pilih tanggal dan waktu yang Anda
            inginkan, dan selesaikan proses pemesanan.`,
  },
  {
    name: "Apakah saya perlu keanggotaan untuk menggunakan GymBlaze?",
    acceptedAnswer: `Tidak, GymBlaze beroperasi dengan sistem bayar saat digunakan.
            Tidak ada komitmen keanggotaan jangka panjang. Anda hanya membayar untuk
            sesi yang Anda pesan.`,
  },
  {
    name: "Bisakah saya mengajak teman ke sesi latihan saya?",
    acceptedAnswer: `Ya, Anda dapat mengajak teman ke sesi latihan Anda. Ruang gym
            pribadi kami dirancang untuk mengakomodasi latihan kelompok, membuat
            berolahraga bersama lebih menyenangkan dan memotivasi.`,
  },
  {
    name: "Peralatan apa saja yang tersedia di GymBlaze?",
    acceptedAnswer: `Gym kami dilengkapi dengan teknologi dan peralatan kebugaran
            terkini, termasuk multi smith machine, adjustable dumbbell, adjustable
            kettlebell, treadmill, dan lainnya. Kami memastikan bahwa Anda memiliki
            semua yang Anda butuhkan untuk latihan yang komprehensif.`,
  },
  {
    name: "Apakah tersedia pelatih pribadi?",
    acceptedAnswer: `Ya, kami memiliki tim pelatih pribadi yang sangat berkualifikasi
            yang menyediakan program pelatihan yang disesuaikan. Mereka akan bekerja
            sama dengan Anda untuk merancang rencana kebugaran yang memenuhi tujuan
            dan kebutuhan spesifik Anda.`,
  },
  {
    name: "Bagaimana cara membatalkan atau menjadwalkan ulang sesi?",
    acceptedAnswer: `Anda tidak dapat membatalkan sesi setelah dikonfirmasi. Namun,
            Anda dapat menjadwalkan ulang sesi dengan mengunjungi situs web kami atau
            menghubungi admin kami.`,
  },
  {
    name: "Langkah-langkah keamanan apa yang diterapkan di GymBlaze?",
    acceptedAnswer: `Kami memprioritaskan keselamatan dan kesehatan Anda. Fasilitas
            kami secara rutin dibersihkan dan disanitasi, dan kami mengikuti semua
            pedoman kesehatan untuk memastikan lingkungan latihan yang aman. Selain
            itu, ruang gym pribadi mengurangi risiko paparan terhadap kerumunan besar.`,
  },
  {
    name: "Metode pembayaran apa saja yang diterima?",
    acceptedAnswer: `Kami menerima pembayaran melalui QRIS, virtual account bank,
            berbagai opsi e-wallet seperti OVO, DANA, ShopeePay, LinkAja, AstraPay,
            dan Kredivo.`,
  },
];

useSchemaOrg([
  defineWebPage({
    "@type": "FAQPage",
  }),
  ...faqContent.map((faq) => defineQuestion(faq)),
]);
</script>
