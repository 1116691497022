<template>
  <section
    class="relative w-full overflow-hidden min-h-[500px] h-[90vh] bg-black"
  >
    <div
      class="p-4 py-10 max-w-7xl md:px-8 md:mx-auto flex items-end md:items-center absolute inset-0 text-white z-20"
    >
      <div
        class="flex flex-col w-full md:w-auto text-center md:text-start pb-10 md:pl-6"
      >
        <div
          class="font-black text-[28px] leading-normal md:text-[42px] md:mb-5 max-w-[640px] md:leading-normal md:max-w-[457px] relative"
        >
          {{ $t("index.hero.title") }}
        </div>
        <div
          class="text-sm md:text-lg md:max-w-md leading-normal relative mb-5 whitespace-pre-line"
        >
          {{ $t("index.hero.subtitle") }}
        </div>
        <div>
          <NuxtLink to="/book" class="z-10">
            <AppButton once tabindex="-1">
              <span>Pesan Sekarang</span>
            </AppButton>
          </NuxtLink>
        </div>
      </div>
    </div>
    <div
      class="absolute w-full h-full bg-gradient-to-t md:bg-gradient-to-r from-black via-black via-20% md:via-50% to-transparent z-10"
    ></div>
    <div class="relative h-4/5 md:h-full md:w-1/2 ml-auto z-0">
      <swiper-container class="w-full h-full" :loop="true" :autoplay="true">
        <swiper-slide v-for="i in 4" :key="i">
          <NuxtImg
            :src="`/img/gallery/${i}.jpg`"
            format="webp"
            class="object-cover w-full h-full object-bottom md:object-center"
            alt=""
            :loading="i === 1 ? 'eager' : 'lazy'"
            :preload="i === 1"
            draggable="false"
            ondragstart="return false;"
          />
        </swiper-slide>
      </swiper-container>
    </div>
  </section>
</template>
