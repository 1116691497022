<template>
  <div>
    <header
      class="bg-black lg:pb-0 shadow fixed w-full z-50"
      :class="{ 'pb-6': showMenu }"
    >
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <!-- lg+ -->
        <nav class="relative flex items-center justify-between h-16 lg:h-20">
          <button
            type="button"
            class="absolute sm:relative inline-flex p-2 text-white transition-all duration-200 rounded-md lg:hidden z-10"
            @click="
              showMenu = !showMenu;
              showProfileMenu = false;
            "
          >
            <Icon name="fa6-solid:bars" />
          </button>

          <div class="relative flex-shrink-0 w-full sm:w-auto">
            <NuxtLink
              to="/"
              title=""
              class="flex justify-center items-center"
              role="button"
              @click="
                showMenu = false;
                showProfileMenu = false;
              "
            >
              <NuxtImg
                src="/gymblaze-logo-gold.svg"
                alt="GymBlaze"
                class="w-auto h-12"
                loading="eager"
                preload
              />
            </NuxtLink>
          </div>

          <div class="absolute sm:relative right-0 flex items-center z-10">
            <ClientOnly>
              <div
                v-if="user?.uid"
                class="inline-flex lg:hidden cursor-pointer"
              >
                <button
                  aria-label="Profile"
                  @click="
                    showProfileMenu = !showProfileMenu;
                    showMenu = false;
                  "
                >
                  <img
                    v-if="user?.photoURL"
                    :src="user.photoURL"
                    referrerpolicy="no-referrer"
                    alt=""
                    class="w-10 h-10 mx-auto rounded-full aspect-square ml-2"
                    loading="eager"
                    fetchpriority="high"
                    draggable="false"
                    ondragstart="return false;"
                  />
                  <div
                    v-else
                    class="w-10 h-10 mx-auto rounded-full aspect-square ml-2 bg-gold-500 text-white flex justify-center items-center"
                  >
                    {{ userNameInitial }}
                  </div>
                </button>
                <div
                  v-if="showProfileMenu"
                  class="flow-root top-16 lg:top-20 absolute w-40 right-0 mt-2 bg-black rounded"
                >
                  <ul
                    class="-my-2 divide-y divide-gray-100"
                    @click="showProfileMenu = false"
                  >
                    <li class="py-2">
                      <ul class="space-y-1">
                        <li>
                          <NuxtLink
                            to="/account"
                            class="block rounded-lg px-4 py-2 font-medium text-gray-100 hover:bg-gray-100 hover:text-gray-700 active:bg-gray-100 active:text-gray-700"
                          >
                            Account
                          </NuxtLink>
                        </li>

                        <li>
                          <NuxtLink
                            to="/bookings"
                            class="block rounded-lg px-4 py-2 font-medium text-gray-100 hover:bg-gray-100 hover:text-gray-700 active:bg-gray-100 active:text-gray-700"
                          >
                            My Bookings
                          </NuxtLink>
                        </li>
                      </ul>
                    </li>

                    <li class="py-2">
                      <ul class="space-y-1">
                        <li>
                          <NuxtLink
                            to="/logout"
                            no-prefetch
                            class="block rounded-lg px-4 py-2 font-medium text-gray-100 hover:bg-gray-100 hover:text-gray-700 active:bg-gray-100 active:text-gray-700"
                          >
                            Logout
                          </NuxtLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </ClientOnly>
          </div>

          <div class="hidden lg:flex lg:items-center lg:ml-auto lg:space-x-10">
            <NuxtLink
              href="/personal-trainers"
              title=""
              class="text-base font-medium text-white transition-all duration-200 hover:text-gold-500 focus:text-gold-500"
            >
              Personal Trainers
            </NuxtLink>
            <NuxtLink
              href="/#services"
              title=""
              class="text-base font-medium text-white transition-all duration-200 hover:text-gold-500 focus:text-gold-500"
            >
              Layanan
            </NuxtLink>
            <NuxtLink
              href="/#faq"
              title=""
              class="text-base font-medium text-white transition-all duration-200 hover:text-gold-500 focus:text-gold-500"
            >
              FAQ
            </NuxtLink>
          </div>

          <div class="hidden lg:inline-flex items-center">
            <NuxtLink to="/book">
              <AppButton tabindex="-1" class="px-4 py-3 ml-10"
                >Pesan Sekarang</AppButton
              >
            </NuxtLink>

            <LocaleChanger class="ml-4" />
          </div>

          <ClientOnly>
            <div
              v-if="user?.uid"
              class="relative hidden lg:inline-flex cursor-pointer"
            >
              <button
                aria-label="Profile"
                @click="showProfileMenu = !showProfileMenu"
              >
                <img
                  v-if="user?.photoURL"
                  :src="user.photoURL"
                  referrerpolicy="no-referrer"
                  alt=""
                  class="w-12 h-12 mx-auto rounded-full aspect-square ml-8"
                  loading="eager"
                  fetchpriority="high"
                  draggable="false"
                  ondragstart="return false;"
                />
                <div
                  v-else
                  class="w-12 h-12 mx-auto rounded-full aspect-square ml-8 bg-gold-500 text-white flex justify-center items-center"
                >
                  {{ userNameInitial }}
                </div>
              </button>
              <div
                v-if="showProfileMenu"
                class="flow-root top-16 lg:top-20 absolute w-40 right-0 mt-2 bg-black rounded"
              >
                <ul
                  class="-my-2 divide-y divide-gray-100"
                  @click="showProfileMenu = false"
                >
                  <li class="py-2">
                    <ul class="space-y-1">
                      <li>
                        <NuxtLink
                          to="/account"
                          class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-100 hover:bg-gray-100 hover:text-gray-700 active:bg-gray-100 active:text-gray-700"
                        >
                          Account
                        </NuxtLink>
                      </li>

                      <li>
                        <NuxtLink
                          to="/bookings"
                          class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-100 hover:bg-gray-100 hover:text-gray-700 active:bg-gray-100 active:text-gray-700"
                        >
                          My Bookings
                        </NuxtLink>
                      </li>
                    </ul>
                  </li>

                  <li class="py-2">
                    <ul class="space-y-1">
                      <li>
                        <NuxtLink
                          to="/logout"
                          no-prefetch
                          class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-100 hover:bg-gray-100 hover:text-gray-700 active:bg-gray-100 active:text-gray-700"
                        >
                          Logout
                        </NuxtLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </ClientOnly>
        </nav>

        <!-- xs to lg -->
        <div v-auto-animate="{ duration: 50 }">
          <nav
            v-if="showMenu"
            class="flex flex-col gap-4 items-center pt-4 pb-6 bg-black border border-gray-700 rounded-md shadow-md lg:hidden mt-4"
          >
            <div class="flow-root" @click="showMenu = false">
              <div class="flex flex-col px-6 -my-2 space-y-1 items-center">
                <NuxtLink
                  to="/personal-trainers"
                  title=""
                  class="inline-flex py-2 text-base font-medium text-white transition-all duration-200 hover:text-gold-500 focus:text-gold-500"
                >
                  Personal Trainers
                </NuxtLink>
                <NuxtLink
                  to="/#services"
                  title=""
                  class="inline-flex py-2 text-base font-medium text-white transition-all duration-200 hover:text-gold-500 focus:text-gold-500"
                >
                  Layanan
                </NuxtLink>
                <NuxtLink
                  to="/#faq"
                  title=""
                  class="inline-flex py-2 text-base font-medium text-white transition-all duration-200 hover:text-gold-500 focus:text-gold-500"
                >
                  FAQ
                </NuxtLink>
              </div>
            </div>

            <div class="px-6 w-full">
              <NuxtLink to="/book" @click="showMenu = false">
                <AppButton
                  tabindex="-1"
                  class="w-full inline-flex px-4 py-3 text-base"
                >
                  Pesan Sekarang
                </AppButton>
              </NuxtLink>
            </div>

            <LocaleChanger @change="showMenu = false" />
          </nav>
        </div>
      </div>
    </header>
    <div class="h-16 lg:h-20 w-full"></div>
  </div>
</template>

<script setup lang="ts">
import { getCurrentUser } from "vuefire";

const showMenu = ref(false);
const showProfileMenu = ref(false);

const user = await getCurrentUser();
const userNameInitial = computed(
  () =>
    user?.displayName
      ?.match(/^(\w)\w*\s+(\w{1,2})/)
      ?.slice(1)
      ?.join("") ?? "",
);
</script>
