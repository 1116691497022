<template>
  <div class="locale-changer hidden">
    <!-- TODO: P3 NiceToHave: i18n -->
    <div class="relative w-11 h-full">
      <button
        class="p-3 block text-center"
        @click="showSwitcher = !showSwitcher"
      >
        <Icon name="fa6-solid:globe" class="text-gray-700 w-5 h-5 !align-sub" />
      </button>
      <div v-if="showSwitcher" class="absolute bg-white shadow-md">
        <button
          v-for="loc in locales"
          :key="`locale-${loc}`"
          class="p-3 text-center hover:bg-gray-200"
          :value="loc.code"
          @click="
            setLocale(loc.code);
            showSwitcher = false;
            $emit('change');
          "
        >
          {{ loc.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineEmits<{
  (e: "change"): void;
}>();

const { locales, setLocale } = useI18n();

const showSwitcher = ref(false);
</script>
