<template>
  <section class="py-10 bg-silver-900 sm:py-16">
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
      <div
        class="text-center sm:flex sm:items-center sm:justify-center sm:text-left"
      >
        <h2 class="text-4xl font-bold text-white text-balance">
          Pesan Sesi Anda Sekarang
        </h2>
      </div>
      <div class="mt-8 text-center md:mt-16">
        <NuxtLink to="/book">
          <AppButton once tabindex="-1">Pesan Sekarang</AppButton>
        </NuxtLink>
      </div>
    </div>
  </section>
</template>
