<template>
  <div
    :style="{
      background: isLoading ? 'black' : 'transparent',
    }"
  >
    <NuxtPwaManifest />
    <AppHeader />
    <NuxtLoadingIndicator />
    <div v-if="isLoading" class="flex justify-center items-center h-app">
      <AppLoader />
    </div>
    <main class="min-h-app bg-black text-white">
      <NuxtPage
        :style="{
          visibility: isLoading ? 'hidden' : 'visible',
        }"
      />
    </main>
    <AppFooter
      :style="{
        visibility: isLoading ? 'hidden' : 'visible',
      }"
    />
    <PwaToast />
  </div>
</template>

<script setup lang="ts">
useAppMeta();
const runtimeConfig = useRuntimeConfig();
const route = useRoute();

const maintenanceExceptionPahts = [
  "/",
  "/privacy",
  "/terms",
  "/rules",
  "/maintenance",
];
const isLoading = ref(true);

onMounted(async () => {
  await nextTick();
  isLoading.value = false;
});

watch(
  () => route.path,
  () => {
    if (
      runtimeConfig.public.maintenanceMode &&
      !maintenanceExceptionPahts.includes(route.path) &&
      route.query.debug !== "true"
    ) {
      return navigateTo("/maintenance", { replace: true });
    }
  },
  { immediate: true },
);
</script>
