type UseAppMetaParam = {
  title?: string;
};

export const useAppMeta = (options?: UseAppMetaParam) => {
  const i18nHead = useLocaleHead();
  const siteConfig = useSiteConfig();

  const title = siteConfig.name;
  const description = siteConfig.description;
  // TODO: P4 Refactor: runtime config base url
  const image = "https://gymblaze.id/gymblaze-logo-gold-black.png";

  useServerSeoMeta({
    ogImage: image,
    twitterCard: "summary_large_image",
    twitterTitle: title,
    twitterDescription: description,
    appleMobileWebAppTitle: "GymBlaze",
    applicationName: "GymBlaze",
    msapplicationTileColor: "#black",
    themeColor: "#000000",
    author: "Gym Master",
    keywords:
      "GymBlaze, GymBlaze.id, gym pribadi, personal trainer, gym tanpa keanggotaan, fitness di Indonesia, gym Jakarta, gym Depok, latihan pribadi, gym bersama teman, program pelatih pribadi, gym eksklusif, sesi latihan fleksibel, fasilitas kebugaran, gym privat, latihan kebugaran, kesehatan dan kebugaran, gym modern, gym mewah, pelatihan kebugaran, kebugaran tanpa komitmen, ruang gym pribadi, teknologi kebugaran",
    ...options,
  });
  // Prevent changes overriden by nuxt.config.ts app.head
  useSeoMeta({
    // title,
    ...options,
  });
  useHeadSafe({
    htmlAttrs: {
      lang: i18nHead.value.htmlAttrs!.lang,
    },
    link: [
      {
        rel: "icon",
        type: "image/svg+xml",
        href: "/favicon.svg",
      },
      {
        rel: "icon",
        type: "image/png",
        href: "/favicon.png",
      },
      ...(i18nHead.value.link || []),
    ],
    meta: [...(i18nHead.value.meta || [])],
  });

  return {};
};
