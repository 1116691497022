<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <AppHeader />
    <div
      class="flex flex-col justify-center items-center w-screen my-64 text-center px-2"
    >
      <p class="text-1xl font-bold mb-3">
        Looks like we need to fix something.
      </p>
      <p class="mb-6">
        You can try again later or send feedback to us: info@gymblaze.id
      </p>
      <p class="mb-3">{{ errorMessage }}</p>
      <p
        v-if="props.error?.message !== props.error?.statusMessage"
        class="mb-6"
      >
        {{ props.error?.statusMessage ?? "" }}
      </p>
      <NuxtLink to="/" @click="clearError">
        <AppButton once tabindex="-1">Back to Home Page</AppButton>
      </NuxtLink>
    </div>
    <AppFooter />
  </div>
</template>

<script setup lang="ts">
// const { t } = useI18n()

const errorMessage = computed(() => {
  return props.error?.message;
});

const props = defineProps<{
  error?: {
    url: string;
    statusCode: number;
    statusMessage: string;
    message: string;
    stack: string;
    data?: string;
  };
}>();

onMounted(() => {
  console.error(props.error?.stack);
  if (props.error?.data) console.error(props.error?.data);
});
</script>
