<template>
  <section
    class="bg-black text-white py-20 lg:py-24 flex flex-col gap-5 justify-center items-center max-w-5xl m-auto"
  >
    <div class="max-w-2xl mx-auto text-center">
      <h2
        class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl"
      >
        Layanan GymBlaze
      </h2>
      <p class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-200">
        Pilihan layanan sesuai kebutuhan fitness kamu
      </p>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-20 w-full p-5">
      <div class="relative border border-white w-full rounded">
        <NuxtImg
          src="/img/services/gym-only.jpg"
          format="webp"
          class="object-cover w-full rounded-t"
          alt=""
          loading="lazy"
          fetchpriority="low"
          draggable="false"
          ondragstart="return false;"
        />
        <div
          class="absolute w-full h-full bottom-0 bg-gradient-to-t from-black via-black via-40% lg:via-30% to-transparent z-10"
        ></div>
        <div class="relative p-6 z-20">
          <h3 class="font-semibold text-xl lg:text-2xl mb-4">Gym Only</h3>
          <p class="text-lg">
            Akses fasilitas gym premium untuk latihan mandiri. Capai tujuan
            fitness-mu dengan peralatan berkualitas.
          </p>
        </div>
      </div>
      <div class="relative border border-white w-full rounded">
        <NuxtImg
          src="/img/services/gym-with-pt.jpg"
          format="webp"
          class="object-cover w-full rounded-t"
          alt=""
          loading="lazy"
          fetchpriority="low"
          draggable="false"
          ondragstart="return false;"
        />
        <div
          class="absolute w-full h-full bottom-0 bg-gradient-to-t from-black via-black via-40% lg:via-30% to-transparent z-10"
        ></div>
        <div class="relative p-6 z-20">
          <h3 class="font-semibold text-xl lg:text-2xl mb-4">
            Gym + Personal Trainer
          </h3>
          <p class="text-lg">
            Nikmati latihan private dengan program fitness khusus dan bimbingan
            langsung dari personal trainer bersertifikat.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
