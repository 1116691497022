<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
onMounted(() => {
  window.addEventListener("vite:preloadError", () => {
    window.location.reload();
  });
});
</script>
